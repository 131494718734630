import LanguageService from "@/service/LanguageService";
let model = {}

model.dataForm = {
    id: undefined,
    t: undefined,
    a: undefined,
    stat: undefined,
    desc: undefined,
    dt: undefined,
    uid: undefined,
    ip: undefined,
    dept: undefined,
    deleted: false
}

model.tableRules = {
    lengthMenu: [10, 20, 50, 100],
    allowPaging: true,
    allowSorting: true,
    allowSelect: false,
    showFormSearch: false,
    showUrl: false,
    total: 0,
    page: 1,
    limit: 20,
    offset: 0,
    sort: "-dt",
    defaultSort: ["dt", "descending"],
    fields: "id,t,a,stat,desc,dt,uid,ip,dept",
    dataSearch: {
        value: {
            t: undefined,
            a: undefined,
            uid: undefined,
            ip: undefined,
            dept: undefined,
            dt: undefined,
            desc: undefined,
            stat: undefined,
        },
        operator: {
            ip: ":regex_i:",
            desc: ":regex_i:",
            t: ":in:",
            a: ":in:",
            dt: ":timeRange:"
        },
    }
}

model.tableHeader = [
    {
        code: "id",
        title: "ID"
    },
    {
        code: "t",
        get title() {
            return LanguageService?.lang?.['t-log-type'] ?? 't-log-type'
        }
    },
    {
        code: "a",
        get title() {
            return LanguageService?.lang?.['t-action-type'] ?? 't-action-type'
        }
    },
    {
        code: "user",
        get title() {
            return LanguageService?.lang?.['t-username'] ?? 't-username'
        }
    },
    {
        code: "ip",
        get title() {
            return LanguageService?.lang?.['t-ip'] ?? 't-ip'
        }
    },
    {
        code: "department_name",
        get title() {
            return LanguageService?.lang?.['t-department'] ?? 't-department'
        }
    },
    {
        code: "dt",
        get title() {
            return LanguageService?.lang?.['t-exe-datetime'] ?? 't-exe-datetime'
        }
    },
    {
        code: "stat",
        get title() {
            return LanguageService?.lang?.['t-status'] ?? 't-status'
        }
    },
    {
        code: "desc",
        get title() {
            return LanguageService?.lang?.['t-description'] ?? 't-description'
        }
    },
]

const logLabel = [
    {
        value: "Account",
        isAdminPrimaryCanView: true
    },
    {
        value: "Department",
        isAdminPrimaryCanView: true
    },
    {
        value: "Probe",
        isAdminPrimaryCanView: true

    },
    {
        value: "Menu",
        isAdminPrimaryCanView: true
    },
    {
        value: "Province",
        isAdminPrimaryCanView: true
    },
    {
        value: "Table",
        isAdminPrimaryCanView: false

    },
    {
        value: "Card",
        isAdminPrimaryCanView: false
    },
    {
        value: "Group",
        isAdminPrimaryCanView: false
    }
];

model.getLogLabel = (role) => {
    let result = []
    if (!role.includes("Admin")) logLabel.forEach((item) => {
        if (item.isAdminPrimaryCanView) result.push(item.value);
    })
    else logLabel.forEach((item) => { result.push(item.value) });
    return result;
}

const actionLabel = {
    Account: [
        {
            value: "Create Account",
            isAdminPrimaryCanView: true
        },
        {
            value: "Update Account",
            isAdminPrimaryCanView: true
        },
        {
            value: "Delete Account",
            isAdminPrimaryCanView: true
        },
        {
            value: "Update Profile",
            isAdminPrimaryCanView: true
        },
        {
            value: "Login",
            isAdminPrimaryCanView: true
        },
        {
            value: "Logout",
            isAdminPrimaryCanView: true
        }
    ],
    Department: [
        {
            value: "Create Department",
            isAdminPrimaryCanView: true
        },
        {
            value: "Update Department",
            isAdminPrimaryCanView: true
        },
        {
            value: "Delete Department",
            isAdminPrimaryCanView: true
        }
    ],
    Probe: [
        {
            value: "Create Probe",
            isAdminPrimaryCanView: true
        },
        {
            value: "Update Probe",
            isAdminPrimaryCanView: true
        },
        {
            value: "Delete Probe",
            isAdminPrimaryCanView: true
        }
    ],

    Menu: [
        {
            value: "Create Menu",
            isAdminPrimaryCanView: false
        },
        {
            value: "Update Menu",
            isAdminPrimaryCanView: false
        },
        {
            value: "Delete Menu",
            isAdminPrimaryCanView: false
        },
        {
            value: "View Menu",
            isAdminPrimaryCanView: true
        }
    ],
    Province: [
        {
            value: "Assign department for Province",
            isAdminPrimaryCanView: true
        },
        {
            value: "UnAssign department for Province",
            isAdminPrimaryCanView: true
        },
        {
            value: "Edit Assign department for Province",
            isAdminPrimaryCanView: true
        }
    ],
    Table: [
        {
            value: "Create Table",
            isAdminPrimaryCanView: false
        },
        {
            value: "Update Table",
            isAdminPrimaryCanView: false
        },
        {
            value: "Delete Table",
            isAdminPrimaryCanView: false
        }
    ],
    Card: [
        {
            value: "Create Card",
            isAdminPrimaryCanView: false
        },
        {
            value: "Update Card",
            isAdminPrimaryCanView: false
        },
        {
            value: "Delete Card",
            isAdminPrimaryCanView: false
        }
    ],
    Group: [
        {
            value: "Create Group",
            isAdminPrimaryCanView: false
        },
        {
            value: "Update Group",
            isAdminPrimaryCanView: false
        },
        {
            value: "Delete Group",
            isAdminPrimaryCanView: false
        }
    ],
};

model.getActionLabel = (role) => {
    let tmpActionLabel = {}
    for (const [key, value] of Object.entries(actionLabel)) {
        tmpActionLabel[key] = [];
        if (!role.includes("Admin")) value.forEach((item) => {
            if (item.isAdminPrimaryCanView) tmpActionLabel[key].push(item.value);
        })
        else value.forEach((item) => tmpActionLabel[key].push(item.value));
    }
    return tmpActionLabel;
}

export default model